


































/* tslint:disable:no-console */
import { Component, Vue } from "vue-property-decorator";

import NavBar from "@/components/Nav-bar.vue";
import BlockBox from "@/components/common/Block-box.vue";
import AppBlockNormal from "@/components/block/App-block-normal.vue";
import AppBlockGrade from "@/components/block/App-block-grade.vue";
import Coupon from "@/components/widget/coupon.vue";
import Gift from "@/components/widget/gift.vue";
import mixins from "@/mixin/index.ts";
import routerMixin from "@/mixin/router-mixin.ts";
import updateTdk from "@/utils/tdk";

import { getHomePageData } from "@/api";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "WealDetail",
  components: { NavBar, BlockBox, AppBlockGrade, Coupon, Gift },
  mixins: [mixins, routerMixin]
})
export default class WealDetails extends Vue {
  // 初始数据可以直接声明为实例的属性
  private appBlock: any = {};
  private giftBlock: any = {};
  private couponBlock: any = {};
  private navs: any[] = [];
  private wealTitle: string = "";
  private tdk(self: any) {
    return {
      title: `-${self.wealTitle}`
    };
  }
  private serverPrefetch(): any {
    return this.fetchData();
  }
  get getDetail(): any {
    return this.$store.state.pageDatas.wealDetailData || {appBlock: {}, giftBlock: {}, couponBlock: {}}
  }
  private fetchData(): any {
    const self: any = this;
    // this.$store.commit('setWealDetailData', null)

    const contentId: number = Number(self.$route.query.contentId) || 0;
    self.$doMotion(self, "LOADING_SWITCH", true);
    return Promise.all([
      this.$store.dispatch("fetchWealDetail", {
        context: this,
        appId: contentId,
        params: {}
      }),
      this.$store.dispatch("fetchAppGift", {
        context: this,
        appId: contentId,
        params: { start: 0, max: 50 }
      }),
      this.$store.dispatch("fetchAppCoupon", {
        context: this,
        params: { app_id: contentId, start: 0, max: 50 }
      })
    ])
      .then(vals => {
        const data: any = { data_source: "wealDetail" };
        data.appBlock = {
          data: [vals[0].value] || [],
          blockLink: "gameDetail",
          data_source: "wealDetail"
        };
        data.giftBlock = {
          data: vals[1].value.gift_list || [],
          data_source: "wealDetail"
        };
        data.couponBlock = {
          data: vals[2].value.list || [],
          data_source: "wealDetail"
        }
        self.wealTitle = (vals[0].value || {}).name;
        self.$store.commit("setWealDetailData", data);
        self.noData = false;
      })
      .catch((err: any) => {
        console.log(err);
        self.$toast.show(err.message || '服务器错误，请刷新！');
      })
      .finally(() => {
        self.$doMotion(self, "LOADING_SWITCH", false);
        updateTdk(self);
      });
  }
  private mounted(): void {
    const self: any = this;
    self.navs.unshift({
      name: self.utils.getUrlParam("source_block_name"),
      link: "/weal/list"
    });
    const contentId: number = Number(self.$route.query.contentId) || 0;
    if (!contentId) {
      this.$router.replace({
        name: "index"
      });
      return;
    }
    if (!self.getDetail.data_source) {
      self.fetchData();
    }
  }
  private destroyed() {
    this.$store.commit('setWealDetailData', {
      appBlock: {},
      giftBlock: {},
      couponBlock: {},
    }) // 修改store
  }
}
